// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  informacionPrueba: false,
  version: '1.0.17',
  tokenIpInfo: 'c97ee65bfea024',
  api: {
    url: 'https://apitest.tuprimero.com.co',
  },
  errorWhiteList: [
    'tucotizacion/terminoscondiciones',
    'sura/soat/Cotizar/Placa',
    'account/login',
    'Sura/Movilidad/Cotizacion/Coberturas/',
    'Sura/Movilidad/Cotizacion/Tarifa',
    'chubb/massive/expedir/lote',
  ],
};
