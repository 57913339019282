import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const API = environment.api;

@Injectable({
  providedIn: 'root',
})
export class GeneralHttpService {
  private httpClient = inject(HttpClient);

  get<T>(endPoint: string, api = API.url): Observable<T> {
    return this.httpClient.get<T>(`${api}/${endPoint}`);
  }

  post<T>(endPoint: string, data?: any, headers?: HttpHeaders): Observable<T> {
    return this.httpClient.post<T>(`${API.url}/${endPoint}`, data, { headers });
  }

  json(urlJson: string): Observable<any> {
    return this.httpClient.get(urlJson);
  }
}
